<template>
  <v-radio-group
    v-model="activeAppearance"
    row
    hide-details
    class="appearance-selection">
    <!-- loading -->
    <template v-if="loading">
      <v-card
        v-for="i in 2"
        :key="`loading-card--${i}`"
        outlined
        flat
        class="appearance-card cy-card appearance-card--loading">
        <span class="appearance-card__link">
          <AppearanceTile class="appearance-card__svg"/>
        </span>
        <div class="appearance-card__selector">
          <v-progress-linear
            indeterminate
            color="grey"/>
        </div>
      </v-card>
    </template>

    <!-- loaded -->
    <template v-else>
      <!-- Default appearance -->
      <v-card
        key="default-appearance"
        outlined
        flat
        class="appearance-card cy-card"
        :disabled="isReadonly(defaultAppearance)">
        <span
          class="appearance-card__link"
          disabled>
          <AppearanceTile
            class="appearance-card__svg"
            :style="{
              '--bg-color': getRGBColor(defaultAppearance).cssString,
              '--text-color': getRGBColor(defaultAppearance).contrastingColor.cssString,
            }"/>
          <v-icon color="white">
            edit
          </v-icon>
        </span>
        <div class="appearance-card__selector">
          <v-radio
            :label="$t('Default')"
            :disabled="isReadonly(defaultAppearance)"
            :readonly="isReadonly(defaultAppearance)"
            :ripple="!isReadonly(defaultAppearance)"
            :value="defaultAppearance.canonical"
            color="secondary"/>
        </div>
      </v-card>

      <!-- Custom appearances -->
      <v-card
        v-for="appearance of customAppearances"
        :key="appearance.canonical"
        outlined
        flat
        class="appearance-card cy-card"
        :disabled="isReadonly(appearance)">
        <router-link
          class="appearance-card__link"
          :to="{
            name: 'orgSettingsAppearance',
            params: { appearanceCanonical: appearance.canonical },
          }"
          :disabled="isReadonly(appearance)"
          :is="isReadonly(appearance) ? 'span' : 'router-link'">
          <AppearanceTile
            class="appearance-card__svg"
            :style="{
              '--bg-color': getRGBColor(appearance).cssString,
              '--text-color': getRGBColor(appearance).contrastingColor.cssString,
            }"/>
          <v-icon color="white">
            edit
          </v-icon>
        </router-link>
        <div class="appearance-card__selector">
          <v-radio
            :label="_.upperFirst(appearance.canonical)"
            :disabled="isReadonly(appearance)"
            :readonly="isReadonly(appearance)"
            :ripple="!isReadonly(appearance)"
            :value="appearance.canonical"
            color="secondary"/>
        </div>
      </v-card>

      <v-card
        v-if="canCreateCustomAppearance"
        v-has-rights-to="'CreateAppearance'"
        flat
        class="appearance-card cy-card cy-card--dashed-border ml-3"
        :to="{ name: 'newOrgSettingsAppearance', params: { appearanceCanonical: 'custom' } }">
        <div class="add-custom">
          <span class="add-custom__text">
            <v-icon class="add-custom__icon">add</v-icon>
            {{ $t('addCustom') }}
          </span>
        </div>
      </v-card>
    </template>
  </v-radio-group>
</template>

<script>
import { mapState } from 'vuex'
import RGBColor from '@/utils/classes/RGBColor'
import AppearanceTile from '@/assets/images/app/appearance-tile.svg'

const MAX_CUSTOM_LIMIT = 1

export default {
  name: 'CyAppearanceSelection',
  components: {
    AppearanceTile,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      appearances: (state) => state.organization.available.appearances,
    }),
    activeAppearance: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      },
    },
    canCreateCustomAppearance () {
      return this.customAppearances < MAX_CUSTOM_LIMIT
    },
    customAppearances () {
      return _.filter(this.appearances, ({ canonical }) => canonical !== 'default')
    },
    defaultAppearance () {
      return _.find(this.appearances, ['canonical', 'default'])
    },
  },
  watch: {
    loading (finished) {
      if (!finished) return
      this.activeAppearance = _.find(this.appearances, 'is_active')?.canonical
    },
  },
  methods: {
    getRGBColor (appearance) {
      return new RGBColor(appearance.color)
    },
    isReadonly (appearance) {
      const { canDisplay } = this.$cycloid.permissions
      return !canDisplay('UpdateAppearance', appearance.canonical)
    },
  },
  i18n: {
    messages: {
      en: {
        addCustom: 'Add custom',
      },
      es: {
        addCustom: 'Añadir @:appearance',
      },
      fr: {
        addCustom: '@:Appearance personalisée',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.appearance-selection {
  display: flex;
}

.appearance-card {
  display: grid;
  width: 144px;
  height: 138px;
  overflow: hidden;

  &:not(:first-child) {
    margin-left: 16px;
  }

  &__link,
  &__svg {
    height: 97px;
  }

  &__link {
    display: grid;
    position: relative;

    &:not([disabled]) {
      .v-icon {
        position: absolute;
        z-index: 2;
        align-self: center;
        justify-self: center;
        padding: 11px;
        border-radius: 50%;
        opacity: 0;
        background: get-color("grey", "dark-3");
      }

      &:hover {
        .v-icon {
          opacity: 1;
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: get-color("black", $alpha: 0.85);
        }
      }
    }
  }

  &__svg {
    ::v-deep {
      .bg-color {
        fill: var(--bg-color);
      }

      .text-color {
        fill: var(--text-color);
      }
    }
  }

  &__selector {
    display: flex;
    align-items: center;
    height: 41px;
    border-top: 1px solid get-color("grey");

    ::v-deep {
      .v-radio {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 8px;
      }

      .v-input--selection-controls__input {
        scale: 0.75;
        margin: 0;
      }

      .v-label {
        padding-left: 8px;
        color: get-color("primary");
        font-size: map.get($font-sizes, "sm");
        font-weight: $font-weight-bold;
      }
    }
  }

  &--loading {
    .appearance-card__svg {
      filter: grayscale(1);
      opacity: 0.75;

      ::v-deep {
        .bg-color {
          fill: get-color("grey", "dark-1");
        }

        .text-color {
          fill: get-color("white");
        }
      }
    }

    .appearance-card__selector {
      ::v-deep {
        .v-progress-linear {
          position: initial;
          opacity: 0.25;
        }
      }
    }
  }

  .add-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    color: get-color("primary", "light-2");

    &__icon {
      margin-left: -6px;
      color: get-color("primary", "light-3");
    }

    &__text {
      text-align: center;
    }

    &:hover,
    &:focus {
      color: get-color("primary", "light-1");

      .add-custom__icon {
        color: get-color("primary", "light-2");
      }
    }
  }
}
</style>
