<template>
  <div class="panels">
    <div class="panels__container">
      <v-card
        elevation="0"
        class="panel panel--info">
        <h4 class="panel__title">
          {{ $t('untranslated.freeTrial') }}
        </h4>
        <p class="mb-2">
          {{ $t('freeTrial.description') }}
        </p>
        <CyButton
          v-if="$showDevThings"
          theme="secondary"
          icon="play_arrow"
          variant="primary"
          :loading="loading"
          @click="$toggle.showConfirmFreeTrialDialog(true)">
          {{ $t('freeTrial.btnText') }}
        </CyButton>
        <CyButton
          v-else
          theme="secondary"
          variant="primary"
          :loading="loading"
          @click.native="openContactUsPage">
          {{ $t('contactUs.btnText') }}
        </CyButton>
      </v-card>

      <v-card
        elevation="0"
        class="panel panel--warning">
        <h4 class="panel__title">
          {{ $t('contactUs.title') }}
        </h4>
        <p
          class="mb-2"
          v-html="$sanitizeHtml($t('contactUs.description'))"/>
        <CyButton
          theme="accent"
          variant="secondary"
          data-cy="link-contact-us"
          :loading="loading"
          @click.native="openContactUsPage">
          {{ $t('contactUs.btnText') }}
        </CyButton>
      </v-card>

      <CyModal
        v-if="showConfirmFreeTrialDialog"
        :header-title="$t('confirmFreeTrialDialog.title')"
        :action-btn-func="startFreeTrial"
        :action-btn-text="$t('confirmFreeTrialDialog.actionBtnText')"
        :cancel-btn-func="() => $toggle.showConfirmFreeTrialDialog(false)"
        :cancel-btn-text="$t('forms.btnCancel')"
        modal-type="info">
        <template slot="default">
          <h3
            class="mt-4"
            v-html="$sanitizeHtml($t('confirmFreeTrialDialog.heading'))"/>
          <p
            class="mt-5 mb-4"
            v-html="$sanitizeHtml($t('confirmFreeTrialDialog.text1'))"/>
          <p
            class="mb-4"
            v-html="$sanitizeHtml($t('confirmFreeTrialDialog.text2'))"/>
          <p
            class="my-6">
            <span>{{ $t('confirmFreeTrialDialog.text3_1') }}</span>
            <a
              href="https://www.cycloid.io/pricing"
              target="_blank"
              title="Cycloid pricing"
              class="cy-link my-4">
              https://www.cycloid.io/pricing
            </a>
            <span>{{ $t('confirmFreeTrialDialog.text3_2') }}</span>
          </p>
        </template>
      </CyModal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { plans } from '@/utils/config/billing'
import { gtmLoginEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyPlansSelection',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showConfirmFreeTrialDialog: false,
  }),
  methods: {
    ...mapActions('organization/billing', [
      'CREATE_SUBSCRIPTION',
    ]),
    openContactUsPage () {
      this.$gtm.trackEvent(gtmLoginEvents.contactUs)
      window.open('https://www.cycloid.io/contact-us', '_blank')
    },
    async startFreeTrial () {
      this.$gtm.trackEvent(gtmLoginEvents.startFreeTrial)
      await this.CREATE_SUBSCRIPTION({ subscriptionPlan: _.cloneDeep(plans.freeTrial) })
      this.$toggle.showConfirmFreeTrialDialog(false)
    },
  },
  i18n: {
    messages: {
      en: {
        confirmFreeTrialDialog: {
          actionBtnText: 'Start Free trial',
          heading: `Starting the free trial will let you try all of Cycloid's awesome features.`,
          text1: `It lasts for ${plans.freeTrial.daysDefault} days and you get access to everything,<br/>so you'll be able to make a solid decision about whether or not Cycloid is right for your tech @:team`,
          text2: `If you'd like to continue using Cycloid once the free trial ends, you can sign up for one of the available subscription plans. Both free and paid subscriptions are available.`,
          text3_1: 'Visit',
          text3_2: 'for more information.',
          title: 'Great choice!',
        },
        contactUs: {
          btnText: 'Contact us',
          description: 'Already convinced?<br/>Contact us to set up your plan together.',
          title: 'Enterprise model',
        },
        freeTrial: {
          btnText: 'Start free trial',
          description: `Try all the features free for ${plans.freeTrial.daysDefault} days.`,
        },
      },
      es: {
        confirmFreeTrialDialog: {
          actionBtnText: 'Comience la prueba gratuita',
          heading: 'Iniciar la prueba gratuita le permitirá probar todas las increíbles funciones de Cycloid.',
          text1: `Tiene una duración de ${plans.freeTrial.daysDefault} días y tiene acceso a todo,<br/>por lo que podrá tomar una decisión sólida sobre si Cycloid es adecuado para su @:team de tecnología o no.`,
          text2: 'Si desea continuar usando Cycloid una vez que finalice la prueba gratuita, puede registrarse en uno de los planes de suscripción disponibles. Están disponibles tanto suscripciones gratuitas como de pago.',
          text3_1: 'Visite',
          text3_2: 'para más información.',
          title: '¡Gran elección!',
        },
        contactUs: {
          btnText: 'Contáctenos',
          description: '¿Ya está convencido?<br/>Contáctenos para configurar su plan con nosotros.',
          title: 'Modèle entreprise',
        },
        freeTrial: {
          btnText: 'Comience el periodo de prueba',
          description: `Pruebe todas las funciones gratis durante ${plans.freeTrial.daysDefault} días.`,
        },
      },
      fr: {
        confirmFreeTrialDialog: {
          actionBtnText: `Commencer l'essai gratuit`,
          heading: `Le démarrage de l'essai gratuit vous permettra d'essayer toutes les fonctionnalités impressionnantes de Cycloid.`,
          text1: `Cela dure ${plans.freeTrial.daysDefault} jours et vous avez accès à tout, vous serez donc en mesure de prendre une décision solide pour savoir si Cycloid convient ou non à votre @:team technique.`,
          text2: `Si vous souhaitez continuer à utiliser Cycloid une fois l'essai gratuit terminé, vous pouvez vous inscrire à l'un des plans d'abonnement disponibles. Des abonnements gratuits et payants sont disponibles.`,
          text3_1: 'Consultez',
          text3_2: `pour plus d'informations.`,
          title: 'Bon choix!',
        },
        contactUs: {
          btnText: 'Nous contacter',
          description: 'Déjà convaincu?<br/>Contactez-nous pour mettre en place votre plan avec nous.',
          title: 'Modelo empresarial',
        },
        freeTrial: {
          btnText: `Commencer l'essai gratuit`,
          description: `Essayez toutes les fonctionnalités gratuitement pendant ${plans.freeTrial.daysDefault} jours.`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.panels {
  &__container {
    display: flex;
    flex-direction: row;
    height: 204px;

    .panel {
      width: 50%;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }

      .cy-btn {
        position: absolute;
        bottom: 24px;
      }
    }
  }

  &__subtitle {
    height: 19px;
    margin-top: -16px;
    color: get-color("grey", "dark-1");
    font-size: map.get($font-sizes, "base");
    font-weight: $font-weight-normal;
    line-height: 150%;
  }
}
</style>
