<template>
  <v-card class="cy-card cy-subscription-info panel panel--with-border">
    <div
      v-if="subscription"
      class="cy-subscription-info__members-count">
      <div class="d-flex justify-space-between">
        <section class="d-flex flex-column">
          <label class="label mb-1">Current plan</label>
          <CyTag>{{ planName }}</CyTag>
        </section>

        <section>
          <label
            class="label"
            v-html="$sanitizeHtml($t('maximumUsersCount', { nb: subscription.members_count }))"/>

          <CyLicenceProgressBar
            data-cy="progress-bar"
            metric="members"
            member-type="subscription"/>

          <span class="users-left">
            <v-icon
              v-if="membersLeft < 1"
              color="error"
              small
              class="mr-1">
              warning
            </v-icon>
            <span v-html="$sanitizeHtml($tc('usersLeft', membersLeft, { nb: membersLeft }))"/>
          </span>
        </section>
      </div>

      <CyNotification
        v-if="!membersLeft"
        class="mt-6"
        :title="$t('noUsersLeftNotificationTitle')"
        :content="$t('noUsersLeftNotificationMessage')"
        :button-label="$t('contactUs')"
        no-bottom-margin
        @click="navigateToCycloidContactUsForm"/>
    </div>

    <div class="cy-subscription-info__container">
      <div class="cy-subscription-info__header">
        <h5 class="cy-subscription-info__header-title">
          {{ $t('subscription') }}
        </h5>
      </div>

      <div class="cy-subscription-info__content">
        <CyNotification
          v-if="isExpired"
          theme="error"
          :title="$t('expiredSubscriptionNotificationTitle')"
          :content="$t('expiredSubscriptionNotificationMessage')"
          no-bottom-margin/>
        <div
          v-else
          class="cy-subscription-info__content-row">
          <div class="cy-subscription-info__content-header">
            <div class="cy-subscription-info__content-status">
              <h6 class="label">
                {{ $t('forms.status') }}
              </h6>

              <p class="cy-subscription-info__content-status-text">
                <span :class="['status-icon', { 'expired': isExpired }]"/>
                <span>{{ isExpired ? $t('expired') : $t('active') }}</span>
              </p>
            </div>

            <div class="cy-subscription-info__content-status-expires-at">
              <h6 class="label">
                {{ $t('expiry') }}
              </h6>

              <p class="timestamp">
                {{ convertTimestampToDate(subscription ? subscription.expires_at : null) }}
              </p>
            </div>

            <CyTag
              v-if="!subscriptionVisible"
              small
              variant="default"
              element-type="button"
              @click="$toggle.subscriptionVisible(true)">
              {{ $t('showMore') }}
            </CyTag>
          </div>
        </div>

        <div
          v-if="subscriptionVisible"
          data-cy="details"
          class="details">
          <div class="details-label">
            <span>{{ $t('maximumUsers') }}</span>
          </div>
          <div class="details-value">
            <span>{{ subscription.members_count }}</span>
          </div>
        </div>

        <CyTag
          v-if="subscriptionVisible"
          small
          variant="default"
          element-type="button"
          @click="$toggle.subscriptionVisible(false)">
          {{ $t('showLess') }}
        </CyTag>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import CyLicenceProgressBar from '@/components/licence-progress-bar.vue'

export default {
  name: 'CySubscriptionInfo',
  components: {
    CyLicenceProgressBar,
  },
  data: () => ({
    subscriptionVisible: false,
  }),
  computed: {
    ...mapState('organization', {
      subscription: (state) => state.detail.subscription,
    }),
    isExpired () {
      return this.subscription.expires_at * 1000 < Date.now()
    },
    planName () {
      return this.subscription.plan.name
    },
    membersLeft () {
      const { current_members: membersCount = null, members_count: maxMembers = null } = this.subscription || {}
      return _.some([membersCount, maxMembers], _.isNull)
        ? null
        : maxMembers - membersCount
    },
  },
  methods: {
    convertTimestampToDate (timestamp) {
      return !_.$isEmpty(timestamp)
        ? moment.unix(Number(timestamp)).format('DD MMM, YYYY') // eslint-disable-line you-dont-need-momentjs/format
        : ''
    },
    navigateToCycloidContactUsForm () {
      window.open('https://www.cycloid.io/contact-us', '_blank')
    },
  },
  i18n: {
    messages: {
      en: {
        active: 'Active',
        contactUs: 'Contact us',
        expired: 'Expired',
        expiredSubscriptionNotificationMessage: 'Your subscription is no longer valid. Renew the subscription to continue using Cycloid.',
        expiredSubscriptionNotificationTitle: 'Subscription expired',
        expiry: 'Expiry',
        maximumUsers: 'Maximum users',
        maximumUsersCount: '@:maximumUsers: <strong>{nb}</strong>',
        noLicence: `Don't have a licence?`,
        noUsersLeftNotificationMessage: 'Please contact us to increase the maximum number of users of your organization.',
        noUsersLeftNotificationTitle: 'You reached maximum user capacity',
        showLess: 'Show less',
        showMore: 'Show more',
        subscription: 'Subscription',
        usersLeft: 'No users left | {nb} user left | {nb} users left',
      },
      es: {
        active: 'Activo',
        contactUs: 'Contacte con nosotros',
        expired: 'Caducado',
        expiredSubscriptionNotificationMessage: 'Su suscripción ya no es válida. Renueve la suscripción para seguir usando Cycloid.',
        expiredSubscriptionNotificationTitle: 'Suscripción vencida',
        expiry: 'Expiración',
        licence: 'Licencia',
        maximumUsers: 'Usuarios máximos',
        maximumUsersCount: '@:maximumUsers: <strong>{nb}</strong>',
        noLicence: '¿No tienes licencia?',
        noUsersLeftNotificationMessage: 'Comuníquese con nosotros para aumentar el número máximo de usuarios de su organización.',
        noUsersLeftNotificationTitle: 'Ha alcanzado la capacidad máxima de usuarios',
        showLess: 'Muestra menos',
        showMore: 'Mostrar más',
        subscription: 'Suscripción',
        usersLeft: 'No quedan usuarios | Queda {nb} usuario | Quedan {nb} usuarios',
      },
      fr: {
        active: 'Actif',
        contactUs: 'Nous contacter',
        expired: 'Expiré',
        expiredSubscriptionNotificationMessage: `Votre abonnement n'est plus valide. Renouvelez l'abonnement pour continuer à utiliser Cycloid.`,
        expiredSubscriptionNotificationTitle: 'Abonnement expirée',
        expiry: 'Expiration',
        licence: 'Licence',
        maximumUsers: 'Utilisateurs maximum',
        maximumUsersCount: '@:maximumUsers: <strong>{nb}</strong>',
        noLicence: `Vous n'avez pas de licence?`,
        noUsersLeftNotificationMessage: `Veuillez nous contacter pour augmenter le nombre maximum d'utilisateurs de votre organisation.`,
        noUsersLeftNotificationTitle: `Vous avez atteint la capacité maximale de l'utilisateur`,
        showLess: 'Montrer moins',
        showMore: 'Montre plus',
        subscription: 'Abonnement',
        usersLeft: 'Aucun utilisateur restant | {nb} utilisateur restant | {nb} utilisateurs restants',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-subscription-info {
  padding: 0;

  &__container {
    padding: 16px 24px;
  }

  .label {
    color: get-color("grey", "dark-1");
    font-size: map.get($font-sizes, "sm");
    font-weight: $font-weight-normal;

    ::v-deep strong {
      color: get-color("primary");
    }
  }

  &__members-count {
    padding: 16px 24px;
    border-bottom: 1px solid get-color("grey");

    ::v-deep .users-left {
      color: get-color("primary");
      font-size: map.get($font-sizes, "sm");
    }
  }

  &__header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title {
    color: get-color("grey", "dark-2");
    font-size: map.get($font-sizes, "sm");
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 14.06px;
    text-transform: uppercase;
  }

  &__content-row {
    display: flex;
    align-items: center;
  }

  &__content-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    height: 40px;
    margin-top: 16px;
  }

  &__content-status-label {
    color: get-color("grey", "dark-1");
    font-size: map.get($font-sizes, "base");
    font-weight: $font-weight-normal;
  }

  &__content-status-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 48px;
    margin-bottom: 0;
    color: get-color("primary");
    font-size: map.get($font-sizes, "sm");
    line-height: 150%;

    .status-icon {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 6px;
      min-width: 6px;
      height: 6px;
      margin-right: 8px;
      border-radius: 50%;
      background-color: get-color("success");
      color: get-color("primary");
    }

    .expired {
      background-color: get-color("error");
      color: get-color("error");
    }
  }

  &__content-status-expires-at {
    margin-right: 20px;
    margin-left: 24px;
    color: get-color("grey", "dark-1");
    justify-self: flex-start;

    > {
      font-weight: $font-weight-normal;
    }

    .timestamp {
      margin-bottom: 0;
      color: get-color("primary");
      font-family: $font-family-base;
      font-size: map.get($font-sizes, "sm");
      font-style: normal;
      line-height: 150%;
    }
  }

  .details {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 402px;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .details-label {
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    & > * {
      margin-top: 4px;
      color: get-color("grey", "dark-1");
      font-size: map.get($font-sizes, "sm");
      font-weight: $font-weight-normal;
    }
  }

  .details-value {
    display: flex;
    flex-direction: column;

    & > * {
      margin-top: 4px;
      color: get-color("primary");
      font-size: map.get($font-sizes, "sm");
    }
  }
}
</style>
