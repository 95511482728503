<template>
  <v-card
    v-bind="$attrs"
    outlined
    class="cy-card feature-toggle">
    <v-card-title class="feature-toggle__header">
      <h2 class="feature-toggle__title">
        {{ title }}
      </h2>
      <span class="feature-toggle__subtitle">
        {{ subtitle }}
      </span>

      <v-switch
        :input-value="enabled"
        class="feature-toggle__switch"
        color="secondary"
        hide-details
        :loading="loading"
        @change="$emit('update:enabled', !enabled)"/>
    </v-card-title>

    <template v-if="enabled">
      <v-divider/>
      <v-card-text class="feature-toggle__enabled-panel">
        <slot name="enabledMessage"/>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'CyFeatureToggle',
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-toggle {
  &__header {
    display: grid;
    grid-template-areas:
      "title    switch"
      "subtitle switch";
    grid-template-columns: 1fr auto;
    gap: 8px 18px;
    padding: 16px 24px;
    word-break: unset;
  }

  &__title {
    grid-area: title;
    color: get-color("grey", "dark-2");
    font-size: map.get($font-sizes, "sm");
    font-weight: $font-weight-bold;
    letter-spacing: 0.05em;
    line-height: 14.06px;
    text-transform: uppercase;
  }

  &__subtitle {
    grid-area: subtitle;
    color: get-color("primary");
    font-size: map.get($font-sizes, "base");
    font-weight: normal;
    line-height: 1.3em;
  }

  &__switch {
    grid-area: switch;
    margin-top: 0;
    padding-top: 0;
  }

  + .feature-toggle {
    margin-top: 18px;
  }
}

</style>
