import { render, staticRenderFns } from "./selection.vue?vue&type=template&id=c62a6072&scoped=true&"
import script from "./selection.vue?vue&type=script&lang=js&"
export * from "./selection.vue?vue&type=script&lang=js&"
import style0 from "./selection.vue?vue&type=style&index=0&id=c62a6072&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c62a6072",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VCard,VIcon,VProgressLinear,VRadio,VRadioGroup})
